.ova-title{
	.sub-title{
		font-size: 15px;
		font-weight: 400;
		margin: 0;
		font-family: var(--primary-font);
		letter-spacing: 6px;
		line-height: 29px;
    	margin-bottom: 6px;

	}
	.title{
		margin: 0;
		margin-bottom: 9px;
		font-size: 45px;
		font-weight: 400;

		a{
			@include transaction( transform, 0.3s, ease);
			color: var(--heading);
			&:hover{
				@include transaction( transform, 0.3s, ease);
				color: var(--primary);
			}
		}
	}

	.description{
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 0;
	}
}