/*Hide in desktop*/
.menu-canvas{
		.menu-toggle{
			display: block;
			z-index: 999;
			padding: 0px;
			width: 25px;
			height: 25px;
			border: none;
			background-color: transparent;

			&:hover{
				background-color: transparent;				
			}
			
			&:before,
		    &:after,
		    span:before {
		      
		        width: 25px;
		        left: 0px;
		        background-color: var(--primary);
		    }

			&:before {
		        transform: translateY(-7px);
		    }

		    &:after {
		        transform: translateY(7px);
		    }
		    

		}

		.container-menu{
			overflow: hidden;
			overflow-y: auto;
			position: fixed;
			top: 0;
			bottom: 0;
			background-color: #222;
			color: #fff;
			width: 290px;
			padding: 30px;
			z-index: 999;
			opacity: 1;
			@include transaction( transform, 0.3s, ease);

			.close-menu{
				padding-top: 0px;
				color: #fff;
				font-size: 1em;
				z-index: 999;
				cursor: pointer;
				text-align: right;
			}
			
			&.dir_left{
				left: 0;
				-webkit-transform: translate3d(-290px, 0, 0);
				transform: translate3d(-290px, 0, 0);

				
			}
			&.dir_right{
				right: 0;
				-webkit-transform: translate3d(290px, 0, 0);
				transform: translate3d(290px, 0, 0);

				
			}
			

		}
		

		.site-overlay{
			width: 100%;		
			height: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: #000;
			opacity: 0;
			visibility: hidden;
			position: fixed;
			z-index: 998;
		}

		&.toggled{
			.menu-toggle{
				&:before,
			    &:after,
			    span:before {
			    	background-color: #fff!important;
			    }
			}

			.container-menu{
				transform: none;
				-webkit-transform: none;
			}
			.site-overlay{
				opacity: 0.8;
				visibility: visible;
				@include transaction( opacity, 0.2s, ease);
			}
		}

		/*Style Menu*/
		.menu {
	        clear: both;
	    }
		ul {
	        list-style: none;
	        &.menu {
	            margin: 0;
	            padding: 0;
	            ul {
	               max-height: 0;
	                overflow: hidden;
	                transition: all ease 0.4s;
	                margin: 0 0px 0 15px;
	                padding: 0;
	                &.show {
	                    max-height: 999px;
	                }
	            }
	        }
	        li {
	            display: inline-block;
	            position: relative;
	            text-align: left;
	            display: block;
	            
	           
	            a {
	                color: #fff;
	                padding: 0.6180469716em 0;
	                display: block;
	                border-bottom: 1px solid #333;
	                &:hover {
	                    opacity: 0.7;
	                }
	            }
	        }

	    }

		.dropdown-toggle {
	        background: transparent;
	        position: absolute;
	        top: 0;
	        right: 0;
	        display: block;
	        padding: 0.5em 0 0.5em 10px;
	        color: inherit;
	        border-radius: 0;
	        border: none;
	        cursor: pointer;

	        &::after {
	            content: "\f104";
	            font-family: "ovaicon";
	            -webkit-font-smoothing: antialiased;
	            -moz-osx-font-smoothing: grayscale;
	            display: inline-block;
	            font-style: normal;
	            font-variant: normal;
	            font-weight: normal;
	            line-height: 1;
	            vertical-align: middle;
	            font-size: 0.6em;
	        }
	        &:focus {
	            outline: none;
	        }
	        &:hover {
	            background: transparent;
	        }
	        &.toggled-on {
	            &:after {
	                content: "\f10c";
	                font-family: "ovaicon";
	            }
	        }
	        
	    }
	
}
