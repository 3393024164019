.ova-wpcf7-customzie{
	position: relative;
	input,textarea{
		font-size: 16px;
		font-weight: 400;
		font-family: var(--primary-font);
		color: var(--text);
		text-transform: capitalize;
	}

	i{
		position: absolute;
		top: 28px;
    	display: flex;
		left: 0%;
		transform: translate(20px, -50%);
	}
	.text-email{
		display: flex;
		column-gap: 24px;
		margin-bottom: 24px;
		position: relative;
		@media(max-width: 479px){
			flex-flow:column;
		}
		.ova-text{
			position: relative;
			width: 50%;
			overflow: hidden;
			@media(max-width: 479px){
				width: 100%;
				margin-bottom: 24px;
			}

			
		}
		.ova-email{
			position: relative;
			width: 50%;
			overflow: hidden;
			@media(max-width: 479px){
				width: 100%;
			}
		}
		input{
			width: 100%;
			border-color: transparent;
			border-bottom: 1px solid var(--light);
			
			padding: 10px 0px 7px 0px;
			padding-left: 0px;
			border-radius: unset;
		}
	}

	.ova-textarea{
		margin-bottom: 30px;
		position: relative;
		i{
			
		    position: absolute;
		    top: 30px;
		    left: 0%;
		    transform: translate(20px, -50%);

		}

		textarea{
			max-height: 112px;
			width: 100%;
			border-color: transparent;
			
			padding: 17px 15px;
			padding-left: 0px;
			border-bottom: 1px solid var(--light);
			border-radius: unset;

		}
	}
	.ova-submit{
		button{
			padding: 13px 29px;
		    font-size: 14px;
    		font-weight: 600;
			font-family: var(--primary-font);
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output, .wpcf7 form.payment-required .wpcf7-response-output{
	border-color: #dc3232;
	color: #dc3232;
}