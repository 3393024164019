.ova-gallery {
    .grid {

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .grid-item {
            float: left;
            padding: 15px;
            &.grid-item-right {
                float: right;
            }

            .gallery-fancybox {
                display: block;
                position: relative;

                img {
                    display: block;
                    width: 100%;
                    border-radius: 12px;
                    object-fit: cover;
                }

                .gallery-container {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    cursor: pointer;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        transition: all .3s ease;
                        opacity: 0;
                        border-radius: 12px;
                    }

                    .gallery-icon {
                        position: absolute;
                        top: 45%;
                        left: 50%;
                        transform: translate(-50%, -50%);   
                        opacity: 0;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        border: 1px solid #fff;
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        transition: all .3s ease;

                        i {
                            color: rgba(255, 255, 255, 0.9);
                            font-size: 14px;
                            line-height: 1;
                        }
                    }
                }
            }

            &:hover {
                .gallery-fancybox {
                    .gallery-container {
                        &:before {
                            opacity: 1;
                        }

                        .gallery-icon {
                            -webkit-transition: all .3s ease;
                            -moz-transition: all .3s ease;
                            -o-transition: all .3s ease;
                            transition: all .3s ease;
                            opacity: 1;
                            top: 50%;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .grid-item {
                width: 50%;
            }
        }

        @media screen and (max-width: 767px) {
            .grid-item {
                width: 100%;
            }
        }
    }
}

.fancybox__content img {
    cursor: zoom-in;
}

.fancybox__caption {
    text-align: center;
}