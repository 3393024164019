.ova-image-box{
	margin-bottom: 34px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 34px;

	@media(max-width: 767px){
	    grid-template-columns: 1fr;
	}

	.item-slider{
			display: flex;
			flex-direction: row;
		    justify-content: start;
		    align-items: center;
		    column-gap: 30px;    

		    &:not(:last-child){
		    	
		    }

		    @media(max-width: 1024px){    
			    column-gap: 15px;
			}

			@media(max-width: 479px){
			    align-items: start;
			    row-gap: 10px;
			    // flex-flow: column;
			}
	
		.ova-image{
			max-width: 170px;
			min-height: 150px;
		    height: 100%;
			object-fit: cover;
			@media(max-width: 1024px){
			    max-width: 130px;
			    min-height: 150px;
			}
			@media(max-width: 479px){
			    max-width: 50%;
			    min-height: 150px;
			}
			
		}
		
		.content-box{
			.title{
				margin: 0;
			    font-family: var(--primary-font);
			    color: var(--heading);
				font-size: 22px;
				font-weight: 500;
				line-height: 1.4;
				margin-bottom: 6px;
			}

			.description{
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				color: var(--text);
				margin-bottom: 10px;
			}

			.currency_symbol{
				font-family: var(--secondary-font);
				color: var(--heading);
				display: flex;
				
				flex-direction: row;
				justify-content: start;

			    font-size: 30px;
				font-weight: 400;
				line-height: 1.2;
			}

		}
	}

	.owl-dots{
		text-align: center;
		margin-top: 2px;
		.owl-dot{
			outline: none;
			span{
				width: 15px;
				height: 5px;
				margin: 3px;
				background: var(--border);
				display: block;
				-webkit-backface-visibility: visible;
				transition: all .2s ease;
				border-radius: 5px;
			}
			&.active{
				span{
					background-color: var(--primary);
					width: 30px;
					border-radius: 5px;
					opacity: 1;
				}
			}
		}
	}

}