
// Layout
$desktop: 1024px;
$tablet: 768px;
$handle: 568px;

// Menu
$submenu-bg-color: #fff;

$line-height-form: 1.5em;

// Input
$border-radius-input: 5px;
$padding-input: 12px 15px;


// Button Submit should like Input 
$border-radius-btn: 5px;
$padding-btn: 12px 25px;


// Button style use for blog and modern button
$border-radius-btn-2: 5px;
$padding-btn-2: 12px 25px;


// background fields in form
$border-field-color: #e0e0e0;
$background-field: #ffffff;



