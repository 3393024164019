.ova-menu-footer {
	.menu {
		list-style: none;
		margin: 0;
		padding: 0;
		a {
			text-decoration: none;
			position: relative;
			-webkit-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
			color: #ffffff;

			&:before{
				position: absolute;
			    content: '';
			    width: 0%;
			    height: 1px;
			    left: 0px;
			    bottom: 0px;
			    transition: all 500ms ease;
			    background-color: var(--primary);
			}
			&:hover{
				color: var(--primary);
				&:before{
					width: 100%;
				}
			}
		}

		.sub-menu {
			list-style: none;
		}

		ul{
			padding: 0;
			margin: 0;
			list-style: none;
		}
	}
}