.ova-video {
	display: inline-block;
	position: relative;
		.icon-content-view{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
		flex-wrap: nowrap;

		&.video_active {
			cursor: pointer;
		}

		&:hover {
			.content {
				&:before {
					-moz-transition: all .3s ease;
					-webkit-transition: all .3s ease;
					transition: all .3s ease;
				}
			}
		}

		.content {

			position: relative;

			width: 120px;
			height: 120px;
			min-width: 64px;
			min-height: 64px;
			border-radius: 50%;
			&:before {
				border: 4px solid #ffffff;
				position: absolute;
				content: "";
				top: 50%;
				left: 50%;
				border-radius: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				
				-webkit-animation: lineScale 2.5s linear infinite;
	    		-moz-animation: lineScale 2.5s linear infinite;
				animation: lineScale 2.5s linear infinite;
			}
			i {
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				font-size: 36px;
				z-index: 1;
				color: #ffffff;

			        position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translate(-50%, -50%);
				    animation: lineScale 2.5s linear infinite;
					@media( max-width: 767px ){
						animation: unset;
						position: relative;
					    display: flex;
					    justify-content: center;
					    align-items: center;
					    height: 100%;
					}

				}	

			

			&:hover {
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;

				&:before{
					border-color: blue;
				}
			}
		}

		.ova-text {
			margin: 0;
			font-weight: 500;
			font-size: 20px;
			line-height: 1.4;
			color: var(--heading);
			margin-left: 16px;

			a {
				color: var(--heading);
				-moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
			}
		}
	}
	
	.modal-container {
		display: none;
		background-color: rgba( 0, 0, 0, .2 );
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 10;

		.modal {
		    width: 900px;
    		height: 500px;
    		position: relative;

    		@media screen and (max-width: 900px) {
    			width: 768px;
    			height: 450px;
    		}

    		@media screen and (max-width: 788px) {
    			width: 600px;
    			height: 350px;
    		}

    		@media screen and (max-width: 620px) {
    			width: 400px;
    			height: 250px;
    		}

    		@media screen and (max-width: 420px) {
    			width: 320px;
    			height: 200px;
    		}

    		@media screen and (max-width: 330px) {
    			width: 300px;
    			height: 180px;
    		}

    		i {
			    position: absolute;
			    right: -10px;
    			top: -35px;
    			padding: 10px;
    			cursor: pointer;
			    font-size: 12px;
			    color: #fff;
			    font-weight: 600;
			    -moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;

				&:hover {
					color: #000;
				}
    		}

    		.modal-video {
    			width: 100%;
    			height: 100%;
    		}
		}
	}


	@keyframes lineScale {
		50% { 
	        -webkit-transform: translate(-50%, -50%) scale(1.5);
	        -moz-animation: translate(-50%, -50%)scale(1.5);
	        transform: translate(-50%, -50%) scale(1.5);
	    }
	    50% { 
	        -webkit-transform: translate(-50%, -50%) scale(0.8);
	        -moz-animation: translate(-50%, -50%)scale(0.8);
	        transform: translate(-50%, -50%) scale(0.8);
	    }
	}	

}


