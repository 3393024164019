.elementor-widget-tabs {
	.elementor-tabs {
		.elementor-tabs-content-wrapper {
			> .elementor-tab-mobile-title {
				&:before {
					font-size: 14px;
					float: right;
					content: "\f199";
					font-family: ovaicon !important;
				    font-style: normal;
				    font-weight: normal !important;
				    font-variant: normal;
				    text-transform: none;
				    line-height: inherit;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				}

				&.elementor-active {
					&:before {
						content: "\f19b";
					}
				}
			}
		}
	}
}