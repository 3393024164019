img{ 
	max-width: 100%; 
	height: auto;
}

.alignleft,
img.alignleft {
	margin-right: 2.2em;
    margin-bottom: 1em;
	display: inline;
	float: left;
}

.alignright, 
img.alignright {
	margin-left: 1.5em;
	margin-bottom: 1em;
	display: inline;
	float: right;
}

.aligncenter, 
img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}

/** Fix for img ajax contact form 7  */
img.ajax-loader{
	width: 16px; 
	height: 16px; 
	display: block; 
	text-align: center; 
	margin: 0 auto; 
	margin-left: auto;
}

.wp-smiley {
	margin: 0 !important;
	max-height: 1em;
}

.size-full {}
.size-large {}
.size-medium {}
.size-thumbnail {}

.bypostauthor{ 
	position: relative;
}
