.ova-room-list-carousel {

	.room-content {
		text-align: center;

		&:hover {
			.room-image {
                img {
                 	@include transaction( all, 0.5s, ease);
					transform: translateY(-10px);
                }
			}		
		}

		.room-image {
			display: inline-block;
			position: relative;
			width: 100%;
			img {
				@include transaction( all, 0.5s, ease);
				border-radius: 5px;
			}
		}

		.room-info {
			padding: 0;
			list-style-type: none;
			display: inline-flex;
			align-items: center;
			li{
				position: relative;
				display: flex;
				padding: 0 12px;
				color: #fff;
				&:not(:last-child) {
					&:before {
						position: absolute;
						content: "";
						top: 50%;
						right: -3px;
						width: 5px;
						height: 5px;
						background-color: #fff;
						border-radius: 50%;
					}
				}
			}
		}

		.room-title {
			@include transaction( all, 0.3s, ease);
			margin: 0;
			font-size: 33px;
			line-height: 1;
			font-weight: 400;
			color: #fff;
			padding-bottom: 23px;
			&:hover {
				@include transaction( all, 0.3s, ease);
				color: var(--primary);
			}
		}

		.room-price {
			@include transaction( all, 0.5s, ease);
			display: inline-block;
			color: #fff;
			.woocommerce-Price-amount {
				color: var(--primary);
				font-size: 30px;
				line-height: 1;
			}
			.text-after {
				font-weight: 500;
			}
		}

		&.room-content-2 {
			.room-image {
				img {
                    border-radius: 0;
				}
				margin-bottom: 40px;
			}
			.room-info {
				li {
					color: var(--text);
					&:not(:last-child){
						&:before {
							content: none;
						}
					}
					i {
						font-size: 22px;
						margin-right: 10px;
					}
				}
			}
			.room-title {
				color: var(--heading);
				padding-bottom: 0;
				&:hover {
					color: var(--primary);
				}
			}
			.room-price {
				position: absolute;
				bottom: -20px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1;
				padding: 12px 16px;
				background-color: #fff;
				color: var(--text);
				line-height: 1;
				box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
				.text-before {
					font-size: 20px;
					font-weight: 500;
				}
				.woocommerce-Price-amount, .text-after{
					font-size: 20px;
					font-weight: 600;
					color: var(--primary);
				}
			}
			.ova-room-book-now {
				display: block;
				margin-top: 10px;
				color: var(--text);
				&:hover {
					color: var(--primary);
				}
				.text-button {
					font-size: 19px;
					font-weight: 500;
					text-transform: uppercase;
				}
				i {
                   font-size: 13px;
                   line-height: 1;
				}
			}
		}

	}

	.owl-carousel {
        .owl-nav {
			button {
				transition: all .3s ease;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-o-transition: all .3s ease;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
	            position: absolute;
	            top: -126px;
	            right: 30px;
				width: 60px;
				height: 60px;
				
	            &.owl-prev, &.owl-next  {
	            	background: #fff;
	            	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25); 
					&:hover {
						@include transaction( all, 0.3s, ease);
						background-color: var(--primary);
		            	i {
		            		@include transaction( all, 0.3s, ease);
		            		color: #fff;
		            	}
		            }
				}

				&.owl-prev {
					margin-right: 74px;
				}

				i {
					@include transaction( all, 0.3s, ease);
					font-size: 24px;
					display: inline-flex;
					color: var(--primary);
				}
			}	
		}
	}

	.owl-dots {
		margin-top: 65px;
		text-align: center;
		@media (max-width: 767px) {
			margin-top: 40px;
		}
		.owl-dot{
			outline: none;
			span{
				width: 15px;
				height: 5px;
				margin: 0 3px;
				background: var(--border);
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity .2s ease;
				border-radius: 2px;
			}
			&.active{
				span{
					background: var(--primary);
					width: 30px;
				}
			}
		}
	}
}