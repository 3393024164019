.ova-testimonial{
    display: block;
    align-items: center;
    text-align: center;

    @media (max-width: 830px) {
    	grid-template-columns: 15% 85%;
		align-items: flex-start;
	}
	@media (max-width: 520px) {
    	grid-template-columns: 0% 100%;
	}

	.info{
		.sub-title {
			display: inline-block;
			font-weight: 400;
			font-size: 15px;
			line-height: 1.2;
			color: var(--heading);
			text-transform: uppercase;
			letter-spacing: 6px;
			position: relative;
			font-family: var(--primary-font);

			margin: 0;
			padding-bottom: 10px;

		}

		.title {
			margin: 0;
			font-weight: 400;
			font-size: 45px;
			line-height: 1.2;
			color: var(--heading);
			padding-bottom: 105px;
			text-transform: uppercase;
		}
	}
    .slide-for {
    	margin-bottom: 40px;
    	.slick-track {
    		display: inline-block;
    		margin: 0;
    		width: auto !important;
    		margin-left: -35px;
    		transform: translate3d(0px, 0px, 0px) !important;
    		.slick-slide{
    			margin-right: -35px;
    		}
    		@media (max-width: 520px) {
		    	// display: none;
			}
    	}
    	
    	.slick-current {
    		.small-img {
    			img {
    				transform: scale(1.1); 
    			}
    		}
    	}
        .small-img {
        	position: relative;
	        overflow: hidden;
	        border-radius: 50%;
	        
	    	img{
	    		transform: scale(1); 
				display: block;
				width: 90px;
				height: 90px;
				object-fit: cover;
				border-radius: 50%;
				border: 2px solid #fff;
			}
	    }
    }

	.slide-testimonials{
		.client-info{
			display: flex;
			align-items: center ;
			justify-content: center;

			@media (max-width: 830px) {
				flex-direction: column;
			}

			.info {
                flex: 0 0 72%;
                position: relative;

				p.ova-evaluate{
					font-family: var(--secondary-font);
					margin: 0;
					font-size: 40px;
					font-weight: 400;
					color: var(--text);
					padding-bottom: 37px;
					line-height: 1.4;
				}

				.name-job{
					display: block;
					position: relative;
					padding-top: 38px;

					&:before{
						content: '';
						width: 90px;
						height: 1px;
						background-color: var(--primary);

						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					.name{
						margin: 0;
						font-size: 24px;
						line-height: 1.2;
						font-weight: 500;
						color: #222222;
						letter-spacing: 0.2px;
						
						padding-bottom: 4px;
					}
					.job{
						margin: 0;
						font-size: 16px;
						font-weight: 500;
						line-height: 1.2;
						text-transform: capitalize;
						color: var(--light);
					}
				}		
			}
		}

		.slick-prev, .slick-next {

			@include transaction( all, 0.3s, ease);
			display: inline-flex;
			justify-content: center;
			align-items: center;
			bottom: unset;
			z-index: 1;
			
			&:before {
				@include transaction( all, 0.3s, ease);
				font-family: 'ovaicon';
				font-size: 37px;
				color: var(--text);
			}

			&:hover {
				
				@include transaction( all, 0.3s, ease);
				&:before {
					@include transaction( all, 0.3s, ease);
					color: var(--primary);
				}
			}
		}

		.slick-prev {
			left: 45px;
			
			@media (max-width: 520px) {
		    	
			}
			&:before {
				content: "\f120";
				line-height: 0;
				color: var(--heading);
			}

		}

		.slick-next {
			right: 45px;
			@media (max-width: 520px) {
		    	// right: 45px;
			}
			&:before {
				content: "\f124";
				color: var(--heading);
				line-height: 0;
			}

		}

		
		.slick-dots {
			display: flex;
			justify-content: center;
			bottom: -46px;
			
			list-style-type: none;
			
				li {
					margin: 0 2.5px;
					width: auto;
					height: auto;
				}
				
				button {
					display: block;
					width: 15px;
					height: 5px;
					padding: 0;
					
					border: none;
					border-radius: 2px;
					background-color: var(--border) 	;
					
					text-indent: -9999px;
				}
			
				li.slick-active button {
					background-color: var(--primary);
					width: 30px;
				}
			
		}
	}

	&.version_2{
		text-align: left;

		.slide-testimonials {
				.slick-list {
				    margin-left:-30px;
				    
				    .slick-slide {
					    margin-left:30px;
					}
			  	}
			.client-info {
				border: 1px solid #E5E5E5;
				border-radius: 5px;

				.info {
					flex: 0 0 100%;
					padding: 30px;

					.info-avatar{
						display: flex;
						align-items: center;

						.small-img{
							
							img{
								width: 70px;
								height: 70px;
								object-fit:cover;
								border-radius: 50%;
							}
						}

						.name-job{

							padding-top: 0;
							padding-left: 20px;

							.name{
								font-size: 20px;
								font-weight: 600;
							}

							.job{
								font-size: 16px;
								font-weight: 400;
								color: var(--primary);
							}

							&:before{
								content: unset;
							}
						}

						.icon{
							flex: auto;
							text-align: end;

							i{
								font-size: 46px;
								color: #D6D6D6;
							}
						}
					}
					
					p{
						&.ova-evaluate{
							font-size: 16px;
							font-weight: 400;
							font-family: var(--primary-font);
						}
					}
				}
			}
			
		}
	}

}