/* Custom Animation */

.ova-move-up {
  animation-name: ovaMoveUp;
  -moz-animation-name: ovaMoveUp;
  -webkit-animation-name: ovaMoveUp; }

@-webkit-keyframes ovaMoveUp {
  from {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes ovaMoveUp {
  from {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes ovaMoveUp {
  from {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes ovaMoveUp {
  from {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.ova-move-down {
  animation-name: ovaMoveDown;
  -moz-animation-name: ovaMoveDown;
  -webkit-animation-name: ovaMoveDown; }

@-webkit-keyframes ovaMoveDown {
  from {
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes ovaMoveDown {
  from {
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes ovaMoveDown {
  from {
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes ovaMoveDown {
  from {
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.ova-move-left {
  animation-name: ovaMoveLeft;
  -moz-animation-name: ovaMoveLeft;
  -webkit-animation-name: ovaMoveLeft; }

@-webkit-keyframes ovaMoveLeft {
  from {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes ovaMoveLeft {
  from {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes ovaMoveLeft {
  from {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes ovaMoveLeft {
  from {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.ova-move-right {
  animation-name: ovaMoveRight;
  -moz-animation-name: ovaMoveRight;
  -webkit-animation-name: ovaMoveRight; }

@-webkit-keyframes ovaMoveRight {
  from {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes ovaMoveRight {
  from {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes ovaMoveRight {
  from {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes ovaMoveRight {
  from {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.ova-scale-up {
  animation-name: ovaScaleUp;
  -moz-animation-name: ovaScaleUp;
  -webkit-animation-name: ovaScaleUp; }

@-webkit-keyframes ovaScaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  to {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-moz-keyframes ovaScaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  to {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-ms-keyframes ovaScaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  to {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes ovaScaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  to {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.ova-flip {
  animation-name: ovaFlip;
  -moz-animation-name: ovaFlip;
  -webkit-animation-name: ovaFlip; }

@-webkit-keyframes ovaFlip {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1; } }

@-moz-keyframes ovaFlip {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1; } }

@-ms-keyframes ovaFlip {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1; } }

@keyframes ovaFlip {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1; } }

.ova-helix {
  animation-name: ovaHelix;
  -moz-animation-name: ovaHelix;
  -webkit-animation-name: ovaHelix; }

@-webkit-keyframes ovaHelix {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 1; } }

@-moz-keyframes ovaHelix {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 1; } }

@-ms-keyframes ovaHelix {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 1; } }

@keyframes ovaHelix {
  from {
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  to {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 1; } }

.ova-popup {
  animation: ovaPopup;
  -moz-animation-name: ovaPopup;
  -webkit-animation-name: ovaPopup; }

@-webkit-keyframes ovaPopup {
  from {
    opacity: 0;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    transform: scale(0.4);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1300px;
    perspective: 1300px; }
  70% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    opacity: .8;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out; }
  to {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1; } }

@-moz-keyframes ovaPopup {
  from {
    opacity: 0;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    transform: scale(0.4);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1300px;
    perspective: 1300px; }
  70% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    opacity: .8;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out; }
  to {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1; } }

@-ms-keyframes ovaPopup {
  from {
    opacity: 0;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    transform: scale(0.4);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1300px;
    perspective: 1300px; }
  70% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    opacity: .8;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out; }
  to {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes ovaPopup {
  from {
    opacity: 0;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    transform: scale(0.4);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1300px;
    perspective: 1300px; }
  70% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    opacity: .8;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out; }
  to {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1; } }