.ova-image-box-2{

    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 767px){    
	   flex-flow: column;
	}

	.ova-image-2{
		flex: 0 0 50%;
	    width: 50%;
		position: relative;
		overflow: hidden;
		display: inline-flex;

		@media(max-width: 1024px){    
		    width: 100%
		}
		.ova-image{
			width: 100%;
	    	min-height: 500px;
	    	max-height: 500px;
	    	object-fit: cover;
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;

	    		@media(min-width: 1024px){    
				    -webkit-transform: scale(1.05);
					transform: scale(1.05);
				}

				@media(max-width: 767px){    
				    max-height: auto !important;
				    min-height: auto !important;
				}

			&:hover{
				-webkit-transform: scale(1);
				transform: scale(1);
				-webkit-transition: .3s ease-in-out;
				transition: .3s ease-in-out;
			}
		}

		a{
			display: inline-flex;
			width: 100%;
		}
	}

	.ova-title{
		flex: 0 0 50%;
		width: 50%;
		padding: 0px 60px;

		.description{
			margin-bottom: 37px;
		}

		@media(max-width: 1200px){    
		    padding: 20px;
		}

		@media(max-width: 1024px){    
		    width: 100%
		}

		@media(max-width: 1024px){    
		    padding: 45px;
		}

		@media(max-width: 479px){    
		    padding: 30px;
		}
		
	}

	.read-more{
		display: inline-block;
		font-size: 16px;
	    font-weight: 500;
	    fill: #FFFFFF;
	    color: #FFFFFF;
	    background-color: #D98600;
	    padding: 13px 39px 17px 39px;
	    text-transform: uppercase;
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
	    
	    &:hover{

	    	-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
	    	background-color: var(--heading);

	    }
	}
}

